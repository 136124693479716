import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ValidationService } from '@app/forms/validation.service';
import { AutofocusDirective } from '@sb/shared/util';
import { DialogConfirmComponent, DialogConfirmResult, InputDirective, SbDialogModule, SbDialogService } from '@sb/ui';
import { Observable, startWith, takeUntil } from 'rxjs';

import { SbFormFieldComponent } from '../forms/sb-form-field.component';

@Component({
  selector: 'app-dialog-confirm-high-impact',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SbFormFieldComponent,
    InputDirective,
    AutofocusDirective,
    SbDialogModule,
    DialogConfirmComponent,
  ],
  templateUrl: './dialog-confirm-high-impact.component.html',
})
export class DialogConfirmHighImpactComponent {
  @ViewChild('dialogContent', { static: false })
  private dialogContent!: TemplateRef<unknown>;

  @Input({ required: true })
  public title: string;

  @Input({ required: true })
  public confirmButtonLabel: string;

  @Input({ required: true })
  public matchLabel: string;

  @Input({ required: true })
  public matchError: string;

  @Input({ required: true })
  public matchValue: string;

  private dialogRef: DialogRef<DialogConfirmResult, DialogConfirmComponent> | undefined;

  public formControl = new FormControl('', [Validators.required]);

  public constructor(
    private readonly dialog: SbDialogService,
    private readonly destroyRef: DestroyRef,
  ) {}

  public openDialog(): Observable<DialogConfirmResult> {
    this.resetForm();

    this.dialogRef = this.dialog.openConfirm({
      type: 'danger',
      primary: { text: this.confirmButtonLabel },
      title: this.title,
      contentTemplate: this.dialogContent,
    });

    this.watchFormControlStatus();

    return this.dialogRef.closed;
  }

  public closeDialog() {
    this.dialogRef?.close();
    this.dialogRef = undefined;
  }

  private watchFormControlStatus() {
    void this.formControl.statusChanges
      .pipe(startWith(this.formControl.status), takeUntil(this.dialogRef.closed), takeUntilDestroyed(this.destroyRef))
      .subscribe((status) => {
        this.updatePrimaryButtonDisabled(status === 'INVALID');
      });
  }

  private updatePrimaryButtonDisabled(disabled: boolean) {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.componentInstance.data.primary.disabled = disabled;
  }

  private resetForm() {
    this.formControl.reset();
    this.formControl.setValidators([
      Validators.required,
      ValidationService.matchText(this.matchValue, this.matchError),
    ]);
    this.formControl.updateValueAndValidity();
  }
}
