<ng-template #dialogContent>
  <div class="flex flex-col gap-4">
    <ng-content></ng-content>
    <span class="font-bold">{{ matchValue }}</span>
    <sb-form-field
      [label]="matchLabel"
      [fullWidth]="true"
      [control]="formControl"
      [errorWhen]="['touched']"
      [noPadding]="true"
    >
      <input type="text" sbAutofocus [formControl]="formControl" sbInput />
    </sb-form-field>
  </div>
</ng-template>
